export default class TabFormDescription {
    option = 'option';
    value = 'value';

    constructor(option = '', value = '') {
        this.option = option;
        this.value = value;
    }
}

export {TabFormDescription};
import TabForm from "./classes/tabForm/";
import {Form} from "./classes/form/";
import {InputGroup} from "./classes/form/inputGroup";
import {Input, Select, Checkbox} from "./classes/form/input";
import TabFormDescription from "./classes/tabForm/description";
import {required, numeric, email} from 'vuelidate/lib/validators'

const BusinessFormTabs = [
    new TabForm(0,
        'Открыть ИП',
        'Индивидуальный предприниматель',
        [
            new TabFormDescription('Количество участников', '1'),
            new TabFormDescription('Юридический адрес', 'не требуется<br></br>'),
            new TabFormDescription('Уставный фонд', 'не требуется'),
            new TabFormDescription('Налог', '3, 5, 16% или единый<br>(в зависимости от системы налогообложения)'),
            new TabFormDescription('Штат', 'до 3х работников'),
            new TabFormDescription('Госпошлина', '0,5 базовой величины'),
        ],
        new Form(
            'Регистрация ИП',
            [
                (new InputGroup(
                        (new Input(
                            'tn',
                            '',
                            '1',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'email',
                            '',
                            's.kuznetcova@els24.com',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'subject',
                            '',
                            'Документы для регистрации бизнеса',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'type',
                            '',
                            'ИП',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                                'registration_ip_surname',
                                'Фамилия *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_ip_name',
                                'Имя *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_ip_patronymic',
                                'Отчество *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Select(
                                'registration_ip_gender',
                                'Пол *',
                                null,
                                'col-md-4',
                                '',
                                ['мужской', 'женский'],
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_dob',
                                'Дата рождения *',
                                '',
                                'col-md-4',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_pob',
                                'Место рождения *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Данные о заявителе')
                    .setSubtitle()
                    .setFootnote(),


                (new InputGroup(
                        (new Select(
                                'registration_ip_document_type',
                                'Вид документа *',
                                '',
                                'col-md-4',
                                '',
                                ['Паспорт гражданина РБ', 'Вид на жительство'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_document_series_and_number',
                                'Серия и номер документа *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                'ЯЯЯЯЯЯЯЯЯ'
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_ip_document_id',
                                'Идентификационный номер *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯ'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_document_name_state_body_issued_document',
                                'Наименование государственного органа выдавшего документ *',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_document_validity',
                                'Срок действия *',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_document_date_issue',
                                'Дата выдачи *',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Документ удостоверяющий личность')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Select(
                                'registration_ip_registration_region',
                                'Область *',
                                '',
                                'col-md-3',
                                '',
                                ['Минская', 'Брестская', 'Витебская', 'Гомельская', 'Гродненская', 'Могилёвская'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_registration_district',
                                'Район',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_ip_registration_locality_type',
                                'Тип населенного пункта *',
                                '',
                                'col-md-3',
                                '',
                                ['Город', 'Деревня', 'Поселок', 'Поселок городского типа'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_registration_locality',
                                'Населенный пункт *',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Select(
                                'registration_ip_registration_road_network_type',
                                'Вид улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                ['Улица', 'Проспект', 'Переулок', 'Площадь', 'Проезд', 'Бульвар', 'Тракт', 'Пром/узел', 'Пром/зона'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_registration_road_network',
                                'Наименование элемента улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_ip_registration_postcode',
                                'Почтовый индекс *',
                                '',
                                'col-md',
                                '',
                                '',
                                '######'
                            )
                        ).setValidatorOptions({
                            required,
                            numeric
                        }),
                        (new Input(
                                'registration_ip_registration_house',
                                'Дом *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_registration_housing',
                                'Корпус',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false
                        }),
                        (new Select(
                                'registration_ip_registration_kind',
                                'Вид *',
                                '',
                                'col-md',
                                '',
                                ['Квартира', 'Офис', 'Комната', 'Кабинет', 'Общежитие', 'Павильон', 'Помещение', 'Частный дом'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_registration_number',
                                '№ *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Адрес регистрации')
                    .setSubtitle('Укажите свой адрес в соответствии с документом, удостоверяющим личность. Если вы временно зарегистрированы по месту пребывания, то все равно указать надо адрес постоянной регистрации.')
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ip_mobile_phone_code',
                                'Мобильный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-1 order-md-1',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_landline_phone_code',
                                'Стационарный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-3 order-md-2',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ip_mobile_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-2 order-md-3',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_landline_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-4 order-md-4',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ip_email',
                                'Электронная почта *',
                                '',
                                'col-md-12 order-5',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                            email
                        }),
                    )
                )
                    .setTitle('Контактные данные *')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ip_OKED_code',
                                'ОКЭД (код) *',
                                '',
                                'col-md-4',
                                '<a class="color-black text-underline" href="https://belstat.gov.by/klassifikatory/obschegosudarstvennye-klassifikatory-respubliki-belarus-ispolzuemye-dlya-zapolneniya-gosudarstvennoi-statisticheskoi-otchetnosti/obschegosudarstvennyi-klassifikator-okrb-005-2011-vidy-ekonomicheskoi-deyatelnosti/" target="_blank" referrerpolicy="no-referrer">Все коды ОКЭД можно уточнить на сайте belstat.gov.by</a>',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_OKED_name',
                                'ОКЭД (название) *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ip_OKED_registration_authority',
                                'Регистрирующий орган *',
                                '',
                                'col-md-4',
                                '<a class="color-black text-underline" href="http://egr.gov.by/egrn/index.jsp?content=RegAuthority" target="_blank" referrerpolicy="no-referrer">Определить регистрирующий орган</a>',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Укажите вид деятельности, от которого будет поступать основной доход')
                    .setSubtitle('При выборе регистрирующего органа необходимо учитывать, что регистрация индивидуального предпринимателя производится по месту жительства (постоянная регистрация).')
                    .setFootnote(),
                (new InputGroup(
                        (new Checkbox('registration_ip_additional_information',
                                'Работаю по трудовому договору',
                                'Работаю по трудовому договору', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                        (new Checkbox('registration_ip_additional_information',
                                'Получаю пенсию',
                                'Получаю пенсию', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                        (new Checkbox('registration_ip_additional_information',
                                'Имею право на пособие по уходу за ребенком в возрасте до трех лет',
                                'Имею право на пособие по уходу за ребенком в возрасте до трех лет', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                        (new Checkbox('registration_ip_additional_information',
                                'Обучаюсь (в дневной форме получения образования в учреждении профессионально-технического, среднего специального, высшего образования)',
                                'Обучаюсь (в дневной форме получения образования в учреждении профессионально-технического, среднего специального, высшего образования)', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                        (new Input(
                                'registration_ip_insurance_certificate_number',
                                'Номер страхового свидетельства *',
                                '',
                                'col-md-12',
                                'Должен совпадать с личным номером паспорта',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Checkbox('registration_ip_agree',
                                'Отправляя свои персональные данные, вы даете <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/soglasiye-na-personalnyye-dannyye.pdf">согласие на обработку персональных данных</a> и соглашаетесь с <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/pravila-polzovaniya-servisom.pdf">условиями пользования автоматизированной инструкцией.</a>',
                                'registration_ip_agree', 'col-md-12')
                        ).setValidatorOptions(
                            {required}
                        ),
                    )
                )
                    .setTitle('Дополнительные сведения')
                    .setSubtitle()
                    .setFootnote(),
            ],
        ),
    ),
    new TabForm(1,
        'Открыть ЧУП',
        'Частное унитарное предприятие',
        [
            new TabFormDescription('Количество участников', '1'),
            new TabFormDescription('Юридический адрес', 'жилое или нежилое помещение'),
            new TabFormDescription('Уставный фонд', 'требуется (от 1 рубля)'),
            new TabFormDescription('Налог', 'общая система налогообложения (налог на прибыль, НДС) или упрощенная'),
            new TabFormDescription('Штат', 'без ограничений'),
            new TabFormDescription('Госпошлина', '1 базовая величина'),
        ],
        new Form(
            'Регистрация ЧУП',
            [
                (new InputGroup(
                        (new Input(
                            'tn',
                            '',
                            '2',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'email',
                            '',
                            's.kuznetcova@els24.com',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'subject',
                            '',
                            'Документы для регистрации бизнеса',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'type',
                            '',
                            'ЧУП',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                                'registration_chup_сompany_name_in_russian',
                                'Название компании на русском языке *',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_сompany_name_in_belarusian',
                                'Название компании на белорусском языке *',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_registration_authority',
                                'Регистрирующий орган *',
                                '',
                                'col-md-12',
                                'Определить регистрирующий орган можно здесь <a class="color-black text-underline" href="http://egr.gov.by/egrn/index.jsp?content=RegAuthority" target="_blank" referrerpolicy="no-referrer">egr.gov.by</a>',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Наименование юридического лица')
                    .setSubtitle('Проверьте наименование, заполните заявление на Портале ЕГР <a class="color-black text-underline" href="http://egr.gov.by/egrn/index.jsp?content=eJurReorgCreate" target="_blank" referrerpolicy="no-referrer">(egr.gov.by)</a> и на следующий рабочий день получите согласование использования выбранного названия на ваш электронный адрес.')
                    .setFootnote(),
                (new InputGroup(
                        (new Select(
                                'registration_chup_legal_address_premises_type',
                                'Тип помещения *',
                                '',
                                'col-md-12',
                                '',
                                ['нежилое', 'жилое'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Select(
                                'registration_chup_legal_address_region',
                                'Область *',
                                '',
                                'col-md-3',
                                '',
                                ['Минская', 'Брестская', 'Витебская', 'Гомельская', 'Гродненская', 'Могилёвская'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_legal_address_district',
                                'Район',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_chup_legal_address_locality_type',
                                'Тип населенного пункта *',
                                '',
                                'col-md-3',
                                '',
                                ['Город', 'Деревня', 'Поселок', 'Поселок городского типа'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_legal_address_locality',
                                'Населенный пункт *',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_chup_legal_address_road_network_type',
                                'Вид улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                ['Улица', 'Проспект', 'Переулок', 'Площадь', 'Проезд', 'Бульвар', 'Тракт', 'Пром/узел', 'Пром/зона'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_legal_address_road_network',
                                'Наименование элемента улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_legal_address_postcode',
                                'Почтовый индекс *',
                                '',
                                'col-md',
                                '',
                                '',
                                '######'
                            )
                        ).setValidatorOptions({
                            required,
                            numeric
                        }),
                        (new Input(
                                'registration_chup_legal_address_house',
                                'Дом *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_legal_address_housing',
                                'Корпус',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false
                        }),
                        (new Select(
                                'registration_chup_legal_address_kind',
                                'Вид *',
                                '',
                                'col-md',
                                '',
                                ['Квартира', 'Офис', 'Комната', 'Кабинет', 'Общежитие', 'Павильон', 'Помещение', 'Частный дом'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_legal_address_number',
                                '№ *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Юридический адрес')
                    .setSubtitle('По адресу общества будет осуществляться взаимодействие с государственными органами. Лучше всего, когда фактический и юридический адреса ЧУП совпадают.')
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_chup_mobile_phone_code',
                                'Мобильный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-1 order-md-1',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_landline_phone_code',
                                'Стационарный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-3 order-md-2',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_chup_mobile_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-2 order-md-3',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_landline_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-4 order-md-4',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_chup_email',
                                'Электронная почта *',
                                '',
                                'col-md-12 order-5',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                            email
                        }),
                    )
                )
                    .setTitle('Контактные данные *')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_chup_authorized_fund_organization',
                                'Денежный *',
                                '',
                                'col-md-12',
                                'Законодательно не установлен минимальный размер уставного фонда',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Размер уставного фонда организации')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_chup_OKED_code',
                                'ОКЭД (код) *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_OKED_name',
                                'ОКЭД (название) *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Выберите вид деятельности, от которого будет поступать основной доход')
                    .setSubtitle('Однако помните, что в уставе будет положение о том, что предприятие может заниматься и другими видами деятельности. Поэтому нет необходимости перечислять все виды деятельности, которыми теоретически планирует заниматься предприятие.')
                    .setFootnote('<a class="color-black text-underline" href="https://belstat.gov.by/klassifikatory/obschegosudarstvennye-klassifikatory-respubliki-belarus-ispolzuemye-dlya-zapolneniya-gosudarstvennoi-statisticheskoi-otchetnosti/obschegosudarstvennyi-klassifikator-okrb-005-2011-vidy-ekonomicheskoi-deyatelnosti/" target="_blank" referrerpolicy="no-referrer">Все коды ОКЭД можно уточнить на сайте belstat.gov.by</a>'),
                (new InputGroup(
                        (new Input(
                                'registration_chup_applicant_surname',
                                'Фамилия *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_chup_applicant_name',
                                'Имя *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_chup_applicant_patronymic',
                                'Отчество *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Select(
                                'registration_chup_applicant_gender',
                                'Пол *',
                                null,
                                'col-md-4',
                                '',
                                ['мужской', 'женский'],
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_dob',
                                'Дата рождения *',
                                '',
                                'col-md-4',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_pob',
                                'Место рождения *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                    )
                )
                    .setTitle('Данные о заявителе')
                    .setSubtitle()
                    .setFootnote(),


                (new InputGroup(
                        (new Select(
                                'registration_chup_applicant_document_type',
                                'Вид документа *',
                                '',
                                'col-md-4',
                                '',
                                ['Паспорт гражданина РБ', 'Вид на жительство'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_document_series_and_number',
                                'Серия и номер документа *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                'ЯЯЯЯЯЯЯЯЯ'
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_chup_applicant_document_id',
                                'Идентификационный номер *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯ'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_document_name_state_body_issued_document',
                                'Наименование государственного органа выдавшего документ *',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_document_validity',
                                'Срок действия *',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_document_date_issue',
                                'Дата выдачи *',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Документ удостоверяющий личность')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Select(
                                'registration_chup_applicant_registration_region',
                                'Область *',
                                '',
                                'col-md-3',
                                '',
                                ['Минская', 'Брестская', 'Витебская', 'Гомельская', 'Гродненская', 'Могилёвская'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_registration_district',
                                'Район',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_chup_applicant_registration_locality_type',
                                'Тип населенного пункта *',
                                '',
                                'col-md-3',
                                '',
                                ['Город', 'Деревня', 'Поселок', 'Поселок городского типа'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_registration_locality',
                                'Населенный пункт *',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Select(
                                'registration_chup_applicant_registration_road_network_type',
                                'Вид улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                ['Улица', 'Проспект', 'Переулок', 'Площадь', 'Проезд', 'Бульвар', 'Тракт', 'Пром/узел', 'Пром/зона'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_registration_road_network',
                                'Наименование элемента улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_chup_applicant_registration_postcode',
                                'Почтовый индекс *',
                                '',
                                'col-md',
                                '',
                                '',
                                '######'
                            )
                        ).setValidatorOptions({
                            required,
                            numeric
                        }),
                        (new Input(
                                'registration_chup_applicant_registration_house',
                                'Дом *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_registration_housing',
                                'Корпус',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false
                        }),
                        (new Select(
                                'registration_chup_applicant_registration_kind',
                                'Вид *',
                                '',
                                'col-md',
                                '',
                                ['Квартира', 'Офис', 'Комната', 'Кабинет', 'Общежитие', 'Павильон', 'Помещение', 'Частный дом'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_registration_number',
                                '№ *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Адрес регистрации')
                    .setSubtitle('Укажите свой адрес в соответствии с документом, удостоверяющим личность. Если вы временно зарегистрированы по месту пребывания, то все равно указать надо адрес постоянной регистрации')
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_chup_applicant_mobile_phone_code',
                                'Мобильный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-1 order-md-1',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_landline_phone_code',
                                'Стационарный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-3 order-md-2',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_chup_applicant_mobile_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-2 order-md-3',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_chup_applicant_landline_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-4 order-md-4',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_chup_applicant_email',
                                'Электронная почта *',
                                '',
                                'col-md-12 order-5',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                            email
                        }),
                        (new Checkbox('registration_chup_this_founder_willnt_director_of_the_organization',
                                'Данный учредитель не будет являться директором организации',
                                'Данный учредитель не будет являться директором организации', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                        (new Checkbox('registration_chup_agree',
                                'Отправляя свои персональные данные, вы даете <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/soglasiye-na-personalnyye-dannyye.pdf">согласие на обработку персональных данных</a> и соглашаетесь с <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/pravila-polzovaniya-servisom.pdf">условиями пользования автоматизированной инструкцией.</a>',
                                'registration_chup_agree', 'col-md-12')
                        ).setValidatorOptions(
                            {required}
                        ),
                    )
                )
                    .setTitle('Контактные данные *')
                    .setSubtitle()
                    .setFootnote(),
            ]
        ),
    ),
    new TabForm(2,
        'Открыть ООО',
        'Общество с ограниченной ответственностью',
        [
            new TabFormDescription('Количество участников', 'от 1 до 50'),
            new TabFormDescription('Юридический адрес', 'нежилое помещение'),
            new TabFormDescription('Уставный фонд', 'требуется (от 1 рубля)'),
            new TabFormDescription('Налог', 'общая система налогообложения (налог на прибыль, НДС) или упрощенная'),
            new TabFormDescription('Штат', 'без ограничений'),
            new TabFormDescription('Госпошлина', '1 базовая величина'),
        ],
        new Form(
            'Регистрация ООО',
            [
                (new InputGroup(
                        (new Input(
                            'tn',
                            '',
                            '3',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'email',
                            '',
                            's.kuznetcova@els24.com',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'subject',
                            '',
                            'Документы для регистрации бизнеса',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                            'type',
                            '',
                            'ООО',
                            'd-none',
                            '',
                            '',
                            '',
                        )).setValidatorOptions({
                            required
                        }),
                        (new Input(
                                'registration_ooo_сompany_name_in_russian',
                                'Название компании на русском языке *',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_сompany_name_in_belarusian',
                                'Название компании на белорусском языке *',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_registration_authority',
                                'Регистрирующий орган *',
                                '',
                                'col-md-12',
                                'При выборе регистрирующего органа необходимо учитывать, что регистрация юридического лица производится по месту его нахождения<br>' +
                                'Уточнить информацию можно здесь <a class="color-black text-underline" href="http://egr.gov.by/egrn/index.jsp?content=RegAuthority" target="_blank" referrerpolicy="no-referrer">egr.gov.by</a>',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Наименование юридического лица')
                    .setSubtitle('Проверьте наименование, заполните заявление на Портале ЕГР <a class="color-black text-underline" href="http://egr.gov.by/egrn/index.jsp?content=eJurReorgCreate" target="_blank" referrerpolicy="no-referrer">(egr.gov.by)</a> и на следующий рабочий день получите согласование использования выбранного названия на ваш электронный адрес.')
                    .setFootnote(),
                (new InputGroup(
                        (new Select(
                                'registration_ooo_legal_address_premises_type',
                                'Тип помещения *',
                                '',
                                'col-md-12',
                                '',
                                ['нежилое', 'жилое'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Select(
                                'registration_ooo_legal_address_region',
                                'Область *',
                                '',
                                'col-md-3',
                                '',
                                ['Минская', 'Брестская', 'Витебская', 'Гомельская', 'Гродненская', 'Могилёвская'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_legal_address_district',
                                'Район',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_ooo_legal_address_locality_type',
                                'Тип населенного пункта *',
                                '',
                                'col-md-3',
                                '',
                                ['Город', 'Деревня', 'Поселок', 'Поселок городского типа'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_legal_address_locality',
                                'Населенный пункт *',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_ooo_legal_address_road_network_type',
                                'Вид улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                ['Улица', 'Проспект', 'Переулок', 'Площадь', 'Проезд', 'Бульвар', 'Тракт', 'Пром/узел', 'Пром/зона'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_legal_address_road_network',
                                'Наименование элемента улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_legal_address_postcode',
                                'Почтовый индекс *',
                                '',
                                'col-md',
                                '',
                                '',
                                '######'
                            )
                        ).setValidatorOptions({
                            required,
                            numeric
                        }),
                        (new Input(
                                'registration_ooo_legal_address_house',
                                'Дом *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_legal_address_housing',
                                'Корпус',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false
                        }),
                        (new Select(
                                'registration_ooo_legal_address_kind',
                                'Вид *',
                                '',
                                'col-md',
                                '',
                                ['Квартира', 'Офис', 'Комната', 'Кабинет', 'Общежитие', 'Павильон', 'Помещение', 'Частный дом'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_legal_address_number',
                                '№ *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Юридический адрес')
                    .setSubtitle('По адресу общества будет осуществляться взаимодействие с государственными органами. Лучше всего, когда фактический и юридический адреса ООО совпадают.')
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ooo_mobile_phone_code',
                                'Мобильный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-1 order-md-1',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_landline_phone_code',
                                'Стационарный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-3 order-md-2',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_mobile_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-2 order-md-3',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_landline_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-4 order-md-4',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_email',
                                'Электронная почта *',
                                '',
                                'col-md-12 order-5',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                            email
                        }),
                        (new Select(
                                'registration_ooo_number_of_founders',
                                'Количество учредителей *',
                                '',
                                'col-md-12',
                                '',
                                ['1', '2'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Контактные данные *')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ooo_authorized_fund_organization',
                                'Денежный *',
                                '',
                                'col-md-12',
                                'Законодательно не установлен минимальный размер уставного фонда',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Размер уставного фонда организации')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ooo_OKED_code',
                                'ОКЭД (код) *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_OKED_name',
                                'ОКЭД (название) *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Выберите вид деятельности, от которого будет поступать основной доход')
                    .setSubtitle('Однако помните, что в уставе будет положение о том, что предприятие может заниматься и другими видами деятельности. Поэтому нет необходимости перечислять все виды деятельности, которыми теоретически планирует заниматься предприятие.')
                    .setFootnote('<a class="color-black text-underline" href="https://belstat.gov.by/klassifikatory/obschegosudarstvennye-klassifikatory-respubliki-belarus-ispolzuemye-dlya-zapolneniya-gosudarstvennoi-statisticheskoi-otchetnosti/obschegosudarstvennyi-klassifikator-okrb-005-2011-vidy-ekonomicheskoi-deyatelnosti/" target="_blank" referrerpolicy="no-referrer">Все коды ОКЭД можно уточнить на сайте belstat.gov.by</a>'),
                (new InputGroup(
                        (new Input(
                                'registration_ooo_applicant_surname',
                                'Фамилия *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_ooo_applicant_name',
                                'Имя *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_patronymic',
                                'Отчество *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Select(
                                'registration_ooo_applicant_gender',
                                'Пол *',
                                null,
                                'col-md-4',
                                '',
                                ['мужской', 'женский'],
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_dob',
                                'Дата рождения *',
                                '',
                                'col-md-4',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_pob',
                                'Место рождения *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                    )
                )
                    .setTitle('Данные о заявителе')
                    .setSubtitle()
                    .setFootnote(),

                (new InputGroup(
                        (new Select(
                                'registration_ooo_applicant_document_type',
                                'Вид документа *',
                                '',
                                'col-md-4',
                                '',
                                ['Паспорт гражданина РБ', 'Вид на жительство'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_series_and_number',
                                'Серия и номер документа *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                'ЯЯЯЯЯЯЯЯЯ'
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_ooo_applicant_document_id',
                                'Идентификационный номер *',
                                '',
                                'col-md-4',
                                '',
                                '',
                                'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯ'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_name_state_body_issued_document',
                                'Наименование государственного органа выдавшего документ *',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_validity',
                                'Срок действия *',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_date_issue',
                                'Дата выдачи *',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Документ удостоверяющий личность')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Select(
                                'registration_ooo_applicant_registration_region',
                                'Область *',
                                '',
                                'col-md-3',
                                '',
                                ['Минская', 'Брестская', 'Витебская', 'Гомельская', 'Гродненская', 'Могилёвская'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_district',
                                'Район',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_ooo_applicant_registration_locality_type',
                                'Тип населенного пункта *',
                                '',
                                'col-md-3',
                                '',
                                ['Город', 'Деревня', 'Поселок', 'Поселок городского типа'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_locality',
                                'Населенный пункт *',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Select(
                                'registration_ooo_applicant_registration_road_network_type',
                                'Вид улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                ['Улица', 'Проспект', 'Переулок', 'Площадь', 'Проезд', 'Бульвар', 'Тракт', 'Пром/узел', 'Пром/зона'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_road_network',
                                'Наименование элемента улично-дорожной сети *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,

                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_postcode',
                                'Почтовый индекс *',
                                '',
                                'col-md',
                                '',
                                '',
                                '######'
                            )
                        ).setValidatorOptions({
                            required,
                            numeric
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_house',
                                'Дом *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_housing',
                                'Корпус',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false
                        }),
                        (new Select(
                                'registration_ooo_applicant_registration_kind',
                                'Вид *',
                                '',
                                'col-md',
                                '',
                                ['Квартира', 'Офис', 'Комната', 'Кабинет', 'Общежитие', 'Павильон', 'Помещение', 'Частный дом'],
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_number',
                                '№ *',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                    )
                )
                    .setTitle('Адрес регистрации')
                    .setSubtitle('Укажите свой адрес в соответствии с документом, удостоверяющим личность. Если вы временно зарегистрированы по месту пребывания, то все равно указать надо адрес постоянной регистрации')
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ooo_applicant_mobile_phone_code',
                                'Мобильный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-1 order-md-1',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_landline_phone_code',
                                'Стационарный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-3 order-md-2',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_mobile_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-2 order-md-3',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required,
                        }),
                        (new Input(
                                'registration_ooo_applicant_landline_phone_number',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-4 order-md-4',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_email',
                                'Электронная почта *',
                                '',
                                'col-md-12 order-5',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                            email
                        }),
                        (new Checkbox('registration_ooo_this_founder_willnt_director_of_the_organization',
                                'Данный учредитель не будет являться директором организации',
                                'registration_ooo_this_founder_willnt_director_of_the_organization', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                    )
                )
                    .setTitle('Контактные данные *')
                    .setSubtitle()
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ooo_contribution_amount_BYN',
                                'Размер вклад BYN *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                            numeric
                        }),
                        (new Input(
                                'registration_ooo_share_size',
                                'Размер доли в % *',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required,
                            numeric
                        }),
                        (new Checkbox('registration_ooo_agree',
                                'Отправляя свои персональные данные, вы даете <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/soglasiye-na-personalnyye-dannyye.pdf">согласие на обработку персональных данных</a> и соглашаетесь с <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/pravila-polzovaniya-servisom.pdf">условиями пользования автоматизированной инструкцией.</a>',
                                'registration_ooo_agree', 'col-md-12')
                        ).setValidatorOptions(
                            {required}
                        ),
                    )
                )
                    .setTitle('Размер вклада в уставный фонд данного учредителя')
                    .setSubtitle('Уставный фонд можно внести только денежными средствами')
                    .setFootnote(),
                (new InputGroup(
                        (new Input(
                                'registration_ooo_applicant_surname_2',
                                'Фамилия',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_name_2',
                                'Имя',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_patronymic_2',
                                'Отчество',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_ooo_applicant_gender_2',
                                'Пол',
                                null,
                                'col-md-4',
                                '',
                                ['мужской', 'женский'],
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_dob_2',
                                'Дата рождения',
                                '',
                                'col-md-4',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_pob_2',
                                'Место рождения',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                    )
                )
                    .setTitle('Данные о заявителе № 2')
                    .setSubtitle()
                    .setFootnote()
                    .setClass('additional-group'),

                (new InputGroup(
                        (new Select(
                                'registration_ooo_applicant_document_type_2',
                                'Вид документа',
                                '',
                                'col-md-4',
                                '',
                                ['Паспорт гражданина РБ', 'Вид на жительство'],
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_series_and_number_2',
                                'Серия и номер документа',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_id_2',
                                'Идентификационный номер',
                                '',
                                'col-md-4',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_name_state_body_issued_document_2',
                                'Наименование государственного органа выдавшего документ',
                                '',
                                'col-md-12',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_validity_2',
                                'Срок действия',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_document_date_issue_2',
                                'Дата выдачи',
                                '',
                                'col-md-6',
                                '',
                                'ДД / ММ / ГГГГ',
                                '##/##/####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                    )
                )
                    .setTitle('Документ удостоверяющий личность')
                    .setSubtitle()
                    .setFootnote()
                    .setClass('additional-group'),

                (new InputGroup(
                        (new Select(
                                'registration_ooo_applicant_registration_region_2',
                                'Область',
                                '',
                                'col-md-3',
                                '',
                                ['Минская', 'Брестская', 'Витебская', 'Гомельская', 'Гродненская', 'Могилёвская'],
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_district_2',
                                'Район',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_ooo_applicant_registration_locality_type_2',
                                'Тип населенного пункта',
                                '',
                                'col-md-3',
                                '',
                                ['Город', 'Деревня', 'Поселок', 'Поселок городского типа'],
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_locality_2',
                                'Населенный пункт',
                                '',
                                'col-md-3',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Select(
                                'registration_ooo_applicant_registration_road_network_type_2',
                                'Вид улично-дорожной сети',
                                '',
                                'col-md-6',
                                '',
                                ['Улица', 'Проспект', 'Переулок', 'Площадь', 'Проезд', 'Бульвар', 'Тракт', 'Пром/узел', 'Пром/зона'],
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_road_network_2',
                                'Наименование элемента улично-дорожной сети',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_postcode_2',
                                'Почтовый индекс',
                                '',
                                'col-md',
                                '',
                                '',
                                '######'
                            )
                        ).setValidatorOptions({
                            required: false,
                            numeric
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_house_2',
                                'Дом',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_housing_2',
                                'Корпус',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false
                        }),
                        (new Select(
                                'registration_ooo_applicant_registration_kind_2',
                                'Вид',
                                '',
                                'col-md',
                                '',
                                ['Квартира', 'Офис', 'Комната', 'Кабинет', 'Общежитие', 'Павильон', 'Помещение', 'Частный дом'],
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_registration_number_2',
                                '№',
                                '',
                                'col-md',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                    )
                )
                    .setTitle('Адрес регистрации')
                    .setSubtitle('Укажите свой адрес в соответствии с документом, удостоверяющим личность. Если вы временно зарегистрированы по месту пребывания, то все равно указать надо адрес постоянной регистрации.')
                    .setFootnote()
                    .setClass('additional-group'),

                (new InputGroup(
                        (new Input(
                                'registration_ooo_applicant_mobile_phone_code_2',
                                'Мобильный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-1 order-md-1',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_landline_phone_code_2',
                                'Стационарный телефон',
                                '+375',
                                'col-md-6 glued-input-top order-3 order-md-2',
                                '',
                                'код: +375__',
                                '+375#####'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_mobile_phone_number_2',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-2 order-md-3',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_landline_phone_number_2',
                                '',
                                '',
                                'col-md-6 glued-input-bottom order-4 order-md-4',
                                '',
                                'номер: ___-__-__',
                                '###-##-##'
                            )
                        ).setValidatorOptions({
                            required: false,
                        }),
                        (new Input(
                                'registration_ooo_applicant_email_2',
                                'Электронная почта',
                                '',
                                'col-md-12 order-5',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                            email
                        }),
                        (new Checkbox('registration_ooo_this_founder_willnt_director_of_the_organization_2',
                                'Данный учредитель не будет являться директором организации',
                                'registration_ooo_this_founder_willnt_director_of_the_organization_2', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                    )
                )
                    .setTitle('Контактные данные')
                    .setSubtitle()
                    .setFootnote()
                    .setClass('additional-group'),

                (new InputGroup(
                        (new Input(
                                'registration_ooo_contribution_amount_BYN_2',
                                'Размер вклад BYN',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                            numeric
                        }),
                        (new Input(
                                'registration_ooo_share_size_2',
                                'Размер доли в %',
                                '',
                                'col-md-6',
                                '',
                                '',
                                ''
                            )
                        ).setValidatorOptions({
                            required: false,
                            numeric
                        }),
                        (new Checkbox('registration_ooo_agree_2',
                                'Отправляя свои персональные данные, вы даете <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/soglasiye-na-personalnyye-dannyye.pdf">согласие на обработку персональных данных</a> и соглашаетесь с <a target="_blank" referrerpolicy="no-referrer" class="color-black text-underline" href="/docs/pravila-polzovaniya-servisom.pdf">условиями пользования автоматизированной инструкцией.</a>',
                                'registration_ooo_agree_2', 'col-md-12')
                        ).setValidatorOptions(
                            {required: false}
                        ),
                    )
                )
                    .setTitle('Размер вклада в уставный фонд данного учредителя')
                    .setSubtitle('Уставный фонд можно внести только денежными средствами')
                    .setFootnote()
                    .setClass('additional-group'),
            ]
        ),
    ),
];

export {BusinessFormTabs};
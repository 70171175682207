import {TabFormDescription} from "./description";
import {Form} from "../form";

export default class TabFormClass {
    id = 0;
    title = 'title';
    subtitle = 'subtitle';

    /**
     * @type TabFormDescription[]
     */
    descriptions = [TabFormDescription];

    form = Form;

    /**
     * @param id
     * @param title
     * @param subtitle
     * @param descriptions
     * @param form
     */
    constructor(id = 0, title = '', subtitle = '', descriptions = [], form = new Form()) {
        this.id = id;
        this.title = title;
        this.subtitle = subtitle;
        this.descriptions = [];

        for (let i = 0; i < descriptions.length; i++) {
            if (descriptions[i] instanceof TabFormDescription) {
                this.descriptions.push(descriptions[i]);
            } else {
                console.error(descriptions[i]);
                console.error('^---descriptions NOT instanceof TabFormDescription');
            }
        }

        this.form = form;
    }
}
